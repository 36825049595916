import { GetStartedLink } from '@rategravity/marketing-components';
import { TrackingEvent } from '@rategravity/marketing-components';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import { onboarding } from '../../../modules/navigation';
import { HeroicButton } from '../../button';

const ButtonWrapperStyle = createOwnUpStyle({
  margin: '40px auto',
  width: 325,
  variants: {
    smallAndDown: {
      margin: '20px auto'
    },
    xsmall: {
      width: '100%'
    }
  }
});

const ButtonWrapper = createOwnUpComponent(OwnUpBox, ButtonWrapperStyle);
export const CTAButton = ({
  text,
  trackingEvent
}: {
  text: string;
  trackingEvent?: TrackingEvent;
}) => (
  <ButtonWrapper>
    <GetStartedLink
      label={text}
      trackingEvent={trackingEvent}
      onboarding={onboarding}
      url={`${process.env.GATSBY_ONBOARDING}`}
    >
      <HeroicButton variant="bestMortgageBanner">{text}</HeroicButton>
    </GetStartedLink>
  </ButtonWrapper>
);
